<template>
  <div class="mt-4">
    <label for="description-field" class="form-label fs-18">
      <span>{{ title }}</span>
      <i
        id="showTips"
        v-if="tips"
        class="ri-question-line"
        @mouseover="mouseOver()"
        @mouseleave="mouseLeave()"
      ></i>
    </label>
    <div class="text-muted">
      {{ hint }}
    </div>
    <b-popover target="showTips" v-model:show="showTips" triggers="">
      <div>{{ tips }}</div>
    </b-popover>
    <DropZone
      :accept="accept"
      @drop.prevent="drop"
      @change.prevent="selectedFile"
      style="min-height: 220px"
      :ipt-id="iptId"
      limit="50M"
    />
    <div class="text-center text-muted py-3" v-if="!selectedList.length">
      {{ $t("base.no_data") }}
    </div>
    <ul class="list-unstyled mb-2" id="dropzone-preview" v-else>
      <div
        class="border rounded"
        v-for="(file, index) of selectedList"
        :key="index"
      >
        <div class="d-flex p-2">
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-14 mb-1">
                <div @click="downloadFile(file.url)">
                  <a :href="file.url" class="text-break">{{ file.filename }}</a>
                </div>
                <!-- <a target="_blank" :href="file.url" :download="file.url"
                                    class="text-break">{{ file.filename }}</a> -->
              </h5>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteFile(index)"
            >
              {{ $t("base.delete") }}
            </button>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import { uploadFile } from "@/service/index";
export default {
  emits: ["update:selectedList"],
  props: {
    selectedList: {
      type: Array,
      default: () => [],
    },
    iptId: {
      type: String,
      default: "dropzoneFile",
    },
    accept: {
      type: String,
      default: () => ".png,.jpg",
    },
    title: {
      type: String,
      default: () => "",
    },
    hint: {
      type: String,
      default: () => "",
    },
    tips: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showTips: false,
    };
  },
  methods: {
    mouseOver() {
      this.showTips = true;
    },
    mouseLeave() {
      this.showTips = false;
    },
    downloadFile(url) {
      window.open(url, "_self");
    },
    selectedFile() {
      let dropzoneFile = document.querySelector("#" + this.iptId).files[0];
      //   console.log(dropzoneFile);
      if (!dropzoneFile) {
        return;
      }
      uploadFile(dropzoneFile)
        .then((res) => {
          if (res && res.data && res.data.payload) {
            let data = _.cloneDeep(this.selectedList);
            data.push(res.data.payload);
            this.$emit("update:selectedList", data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      document.getElementById(this.iptId).value = null;
    },
    drop(e) {
      let dropzoneFile = e.dataTransfer.files[0];
      console.log(dropzoneFile);
      if (!dropzoneFile) {
        return;
      }
      uploadFile(dropzoneFile)
        .then((res) => {
          if (res && res.data && res.data.payload) {
            let data = _.cloneDeep(this.selectedList);
            data.push(res.data.payload);
            this.$emit("update:selectedList", data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      document.getElementById(this.iptId).value = null;
    },
    deleteFile(i) {
      let data = _.cloneDeep(this.selectedList);
      data.splice(i, 1);
      this.$emit("update:selectedList", data);
    },
  },
};
</script>

<style>
</style>
