<template>
	<b-row style="max-width: 200px; height: 100%;">
		<b-col cols="auto">
			<div v-if="enablePre()" class="row" style="position: relative; top: 50%; transform: translateY(-50%);">
				<a href="javascript:;" @click="preClick"><i class="ri-arrow-left-s-line"></i></a>
			</div>
		</b-col>
		<b-col>
			<a href="javascript:;" @click="imgShowClick">
				<img
					:src="imgUrl"
					alt=""
					style="object-fit: contain;"
					@error="
						e => {
							e.target.src = defaultImage;
						}
					"
				/>
			</a>
		</b-col>
		<b-col cols="auto">
			<div v-if="enableNext()" class="row" style="position: relative; top: 50%; transform: translateY(-50%);">
				<a href="javascript:;" @click="nextClick"><i class="ri-arrow-right-s-line"></i></a>
			</div>
		</b-col>
	</b-row>
	<PreviewImg v-model:showPreviewImg="showPreview" :src="imgUrl" v-if="showPreview"></PreviewImg>
</template>

<script>
import PreviewImg from '@/components/common/PreviewImg';
import defaultImage from '@/assets/image.png';
export default {
	components: {
		PreviewImg
	},
	props: {
		files: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			defaultImage,
			showPreview: false,
			current: 0
		};
	},
	methods: {
		preClick() {
			if (this.enablePre()) {
				this.current = this.current - 1;
			}
		},
		nextClick() {
			if (this.enableNext()) {
				this.current = this.current + 1;
			}
		},
		imgShowClick() {
			if (!this.files) {
				return;
			}
			this.showPreview = true;
		},
		enablePre() {
			if (this.files && this.files.length > this.current - 1 && this.current > 0) {
				return true;
			}
			return false;
		},
		enableNext() {
			if (this.files && this.files.length > this.current + 1) {
				return true;
			}
			return false;
		}
	},
	computed: {
		imgUrl() {
			if (this.files && this.files.length && this.files.length > this.current) {
				const item = this.files[this.current];
				return item.url;
			}
			return '';
		}
	}
};
</script>

<style></style>
