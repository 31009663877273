import _ from 'lodash'

export const getFileValuesFromDetail = (detailData, fileFieldName) => {
    const ret = {
        ids: [],
        previews: []
    }
    const fileArr = _.get(detailData, fileFieldName, [])
    if (fileArr == '[]') {
        return ret
    }
    if (!fileArr.length) {
        return ret
    }
    if (typeof fileArr[0] == 'string') {
        console.log('遇到错误，监听获取的值不是文件对象')
        return ret
    }
    for (let i = 0; i < fileArr.length; i++) {
        ret.previews.push({
            ...fileArr[i],
            filename: fileArr[i]['name']
        });
        ret.ids.push(fileArr[i]['id'])
    }
    return ret
}
