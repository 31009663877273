<!-- 标准关联：数据库指标关联 -->
<template>
  <b-modal
      v-model="showModal"
      hide-footer
      :title="title"
      class="v-modal-custom"
      size="lg"
      centered
  >
    <div class="modal-body border-top">
      <div>
        <div class="search-box">
          <input
              type="text"
              class="form-control search-ipt"
              :placeholder="$t('attribute.search_indicator_name')"
              v-model="keyword"
              @input="search"
              @keyup.enter="nextHighlight"
          />
          <i class="ri-search-line search-icon"></i>
          <span class="search-result" v-if="totalHighlightCount">
            {{currentHighlightIndex+1}}/{{totalHighlightCount}}
          </span>
        </div>
        <table class="table mb-0 set-table">
          <thead class="table-light">
          <tr>
            <th style="width: 100px;" scope="col">
              <div class="form-check d-flex justify-content-start align-items-center">
                <input class="form-check-input me-1" type="checkbox" :id="`ckall_${index_type}`" v-model="checkAll" @change="checkAllChange">
                <label class="form-check-label" :for="`ckall_${index_type}`">{{ $t('base.show') }}</label>
              </div>
            </th>
            <th scope="col" style="width: 50px;">{{ $t('base.No') }}</th>
            <th scope="col">{{ $t('attribute.indicator_name') }}</th>
            <th scope="col">{{ $t('attribute.reference') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in data" :key="index" @click="item.editing = false;">
            <td style="width: 100px;" @click="item.show = !item.show">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="item.show">
              </div>
            </td>
            <td style="width: 50px;">
              {{ index + 1 }}
            </td>
            <td>
              <div class="text-break">
                <span v-highlight="keyword" class="text-break cursor-pointer" @click="item.show = !item.show">
                  {{ item.index_name }}
                </span>
              </div>
            </td>
            <td @click.stop="edit(item)">
              <div class="text-break">
                <span v-if="!item.editing">{{item.reference}}</span>
                <b-form-textarea
                    v-else
                    v-model="item.reference"
                    rows="3"
                    max-rows="8"
                    :autofocus="true"
                    @blur="item.editing = false"
                ></b-form-textarea>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div class="modal-footer v-modal-footer" v-if="!readOnly">
      <b-button type="button" variant="light" @click="showModal = false">{{this.$t('base.cancel')}}
      </b-button>
      <b-button type="button" variant="primary" @click="save">{{this.$t('base.save')}}</b-button>
    </div>
    <message-alert
        :alertInfo="alertInfo"
        v-if="formValidataAlert"
    ></message-alert>
  </b-modal>
</template>
<script>
import _ from 'lodash'
import { postDbIndex } from '@/service/index'
import Swal from "sweetalert2";
import HighlightText from "@/directives/HighlightText";
export default {
  directives: {
    highlight: HighlightText,
  },
  components: {},
  props: {

  },
  emits: ['save'],
  data () {
    return {
      showModal: false,
      title: this.$t('attribute.indicators_related'),
      readOnly: false,
      key: '', // garment/fabric....
      code: '', // 6位hscode
      index_type: '', // 指标类型：quality_index/business_index/envrionmental_index
      data: [], // 所有指标，至少包含字段：是否显示、field_name、reference
      checkAll: false,
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      keyword: '',
      currentHighlightIndex: -1,
      totalHighlightCount: 0,
    }
  },
  methods: {
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
    errorAlert(msg) {
      this.openAlert({
        variant: "danger",
        content: msg,
        icon: "ri-error-warning-line",
      });
      setTimeout(() => {
        this.formValidataAlert = false;
      }, 1500);
    },
    successAlert(msg) {
      this.openAlert({
        variant: "success",
        content: msg,
        icon: "ri-check-double-line",
      });
      setTimeout(() => {
        this.formValidataAlert = false;
      }, 1500);
    },
    edit(item) {
      if (item.show) {
        item.editing = true
      }
    },
    checkAllChange() {
      this.data.map(item => {
        item.show = this.checkAll
      })
    },
    search: _.debounce(function () {
      if (!this.keyword) {
        this.currentHighlightIndex = -1
        this.totalHighlightCount = 0
        return
      }
      this.$nextTick(() => {
        this.currentHighlightIndex = -1
        this.nextHighlight()
      })
    }, 200),
    nextHighlight() {
      // 获取所有高亮的元素
      const highlightList = document.querySelectorAll('.highlight')
      if (highlightList.length === 0) return
      this.totalHighlightCount = highlightList.length
      // 如果当前高亮的是最后一个，那么下一个就是第一个
      if (this.currentHighlightIndex === highlightList.length - 1) {
        this.currentHighlightIndex = 0
      } else {
        this.currentHighlightIndex++
      }
      // 获取当前高亮的元素
      const currentHighlight = highlightList[this.currentHighlightIndex]
      // 滚动到当前高亮的元素
      currentHighlight.scrollIntoView({ behavior: 'smooth', block: 'center' })
      document.querySelectorAll('.current-highlight').forEach(item => {
        item.classList.remove('current-highlight')
      })
      const td = currentHighlight.parentNode.parentNode.parentNode
      // 给td加一个类
      td.classList.add('current-highlight')
    },
    open(params = {}, readOnly = false) {
      this.key = params.key
      this.code = params.code
      this.index_type = params.index_type
      this.data = _.cloneDeep(params.data)
      this.data.forEach(item => {
        item.editing = false
      })
      // 如果data中全部都是show=true,那么checkAll=true
      this.checkAll = this.data.every(item => item.show)
      this.readOnly = readOnly
      this.showModal = true
    },
    save () {
      if (!this.code) {
        this.errorAlert(this.$t('attribute.please_set_hscode'))
        return;
      }
      Swal.fire({
        // title: this.$t('base.confirm'),
        text: this.$t('attribute.indicator_related_confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('base.confirm'),
        cancelButtonText: this.$t('base.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.doSave()
        }
      })
    },
    doSave() {
      // 调接口保存，通知父组件更新
      const params = {
        key: this.key,
        code: this.code,
        index_type: this.index_type,
        fields: []
      }
      // 取show=true的field_name和reference
      this.data.forEach(item => {
        if (item.show) {
          params.fields.push({
            field_name: item.field_name,
            reference: item.reference
          })
        }
      })
      postDbIndex(params).then(res => {
        if (!res.code) {
          this.$emit('save', {
            key: this.key,
            code: this.code,
            index_type: this.index_type,
            data: this.data
          })

          // 成功提示
          this.successAlert(this.$t('base.edit_success'))
          setTimeout(() => {
            this.showModal = false
          },1500)
        } else {
          // 失败提示
          this.errorAlert(this.$t('base.fail'))
        }
      })
    },
    close() {
      this.showModal = false
    }
  }
}
</script>
<style>
.set-table tbody {
  display: block;
  height: 60vh;
  overflow: auto;
  width: 100%;
}
.set-table thead, .set-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.search-box {
  width: 70%;
  /*margin: -2.5rem auto 1.5rem;*/
  margin-bottom: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
.search-ipt {
  background: #f6f6f9;
  border: none;
}
.search-ipt:focus {
  background: #f6f6f9!important;
}
.highlight {
  background-color: yellow;
}
.current-highlight .highlight {
  color: #f00;
}
</style>
