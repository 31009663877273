<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <div>{{ $t("attribute.base") }}</div>
      <b-button
        v-if="showEditBtn && can_edit"
        variant="success"
        class="waves-effect waves-light"
        @click="handlerJumpEdit()"
      >
        {{ $t("base.edit") }}
      </b-button>
    </div>
    <div class="card-body">
      <b-row>
        <b-col v-if="!detailType">
          <div class="row text-gray">
            <b-col :cols="6">
              <div>{{ $t("attribute.name") }}:</div>
              <div class="mt-2">{{ name }}</div>
            </b-col>
            <b-col :cols="6">
              <div>{{ $t("attribute.id") }}:</div>
              <div class="mt-2">{{ id }}</div>
            </b-col>
            <b-col :cols="6" class="pt-3">
              <div>{{ $t("attribute.type") }}:</div>
              <div class="mt-2">{{ detail.type }}</div>
            </b-col>
            <b-col :cols="6" class="pt-3">
              <div>{{ $t("attribute.code") }}:</div>
              <div class="mt-2">{{ code }}</div>
            </b-col>
          </div>
        </b-col>
        <b-col v-else>
          <div class="row text-gray">
            <b-col :cols="4">
              <div>{{ $t("attribute.name") }}:</div>
              <div class="mt-2">{{ name }}</div>
            </b-col>
            <b-col :cols="4">
              <div>{{ $t("attribute.size") }}:</div>
              <div class="mt-2">{{ detail.sizing }}</div>
            </b-col>
            <b-col :cols="4">
              <div>{{ $t("attribute.id") }}:</div>
              <div class="mt-2">{{ id }}</div>
            </b-col>
            <b-col :cols="4" class="pt-3">
              <div>{{ $t("attribute.type") }}:</div>
              <div class="mt-2">{{ detail.type }}</div>
            </b-col>
            <b-col :cols="4" class="pt-3">
              <div>{{ $t("attribute.fitting") }}:</div>
              <div class="mt-2">{{ detail.fitting }}</div>
            </b-col>
            <b-col :cols="4" class="pt-3">
              <div>{{ $t("attribute.code") }}:</div>
              <div class="mt-2">{{ code }}</div>
            </b-col>
          </div>
        </b-col>
        <b-col>
          <b-row style="height: 100%">
            <b-col :cols="7"
              ><ImageArrayShow v-if="files" :files="files"></ImageArrayShow
            ></b-col>
            <b-col :cols="5"
              ><img
                style="object-fit: contain; max-width: 120px; height: 100%"
                :src="qrcodeImgUrl"
            /></b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImageArrayShow from "@/views/components/ImageArrayShow.vue";
import QRCode from "qrcode";
import { getIndicator } from "@/utils/common";

export default {
  components: {
    ImageArrayShow,
  },
  props: {
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
    detailType: {
      type: String,
      default: "",
    },
    editType: {
      type: String,
      default: "",
    },
    qrcodeUrl: {
      type: String,
      default: "None",
    },
  },
  data() {
    return {
      qrcodeImgUrl: null,
      showEditBtn: false,
    };
  },
  mounted() {
    let promision = sessionStorage.getItem("set_menu_list");
    if (promision && this.editType) {
      this.showEditBtn = JSON.parse(promision).permissions.includes(
        `${this.editType}.modify`
      );
    }
  },
  methods: {
    handlerJumpEdit() {
      let url = window.location.href;
      let fullPath = url.split("#")[1].split("&")[0];
      let jump = fullPath.split("_detail")[0];
      this.$router.push({ path: jump });
      setTimeout(() => {
        this.$router.push(fullPath + "&type=edit&data_type=" + this.editType);
      }, 0);
    },
    qrcode() {
      const string = this.qrcodeUrl;
      var opts = {
        errorCorrectionLevel: "M",
        margin: 1,
        width: 300,
        height: 300,
        quality: 1,
        color: {
          dark: "#000",
          light: "#FFF",
        },
      };
      QRCode.toDataURL(string, opts, (err, url) => {
        if (err) throw err;
        this.qrcodeImgUrl = url;
      });
    },
  },
  computed: {
    name() {
      if (this.detail["key"] == "fibre" || this.detail["key"] == "yarn") {
        return getIndicator(this.detail.business_index, "product_name");
      }
      return this.detail.name;
    },
    id() {
      if (['textile_bedware', 'textile_home_decor', 'textile_industrial', 'textile_kitchen'].includes(this.detail["key"])) {
        return this.detail['textile_id']
      }
      return this.detail[this.detail["key"] + "_id"];
    },
    can_edit() {
      if (this.detail["can_edit"] == undefined) {
        return true;
      }
      return this.detail["can_edit"];
    },
    code() {
      var value = "";
      const code = this.detail["code"];
      const code_2 = this.detail["code_2"];
      const code_ciq = this.detail["code_ciq"];
      const code_title = this.detail["code_title"];
      if (code) {
        value += code + "-";
      }
      if (code_2) {
        value += code_2 + "-";
      } else {
        value += "-";
      }
      if (code_ciq) {
        value += code_ciq;
      }
      if (code_title) {
        value += "  " + code_title;
      }
      return value;
    },
    files() {
      if (this.detail && this.detail.style) {
        return this.detail.style;
      }
      if (this.detail && this.detail.pictures) {
        return this.detail.pictures;
      }
      return [];
    },
  },
  watch: {
    detail: {
      handler(val) {
        if (val) {
          this.qrcode();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
