

export default {
  beforeMount(el) {
    el.$el_input = el // 记录原来元素的引用
  },
  updated(el, binding) {
    let keyword = binding.value
    let text = el.textContent
    if (keyword) {
      el.innerHTML = text.replace(RegExp(`(${keyword})`, 'g'), "<span class='highlight'>$1</span>");
    } else {
      el.innerHTML = text
    }
  },
  unmounted() {
    // ...
  }
}