export default {
  data() {
    return {
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
    }
  },
  methods: {
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
    errorAlert(msg) {
      this.openAlert({
        variant: "danger",
        content: msg,
        icon: "ri-error-warning-line",
      });
      setTimeout(() => {
        this.formValidataAlert = false;
      }, 1500);
    },
    successAlert(msg) {
      this.openAlert({
        variant: "success",
        content: msg,
        icon: "ri-check-double-line",
      });
      setTimeout(() => {
        this.formValidataAlert = false;
      }, 1500);
    },
  }
}